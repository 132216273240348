.customisable-tls {
	background-color: $aqua;
	overflow: hidden;
	padding: 73px 0;
	position: relative;

	.container {
		position: relative;
		padding-left: 30px;
		padding-right: 30px;
	}

	.owl-stage {
		display: flex;
	}

	&__slide {
		&-quote {
			color: $text_colour;
			font-family: $secondary_font !important;
			font-style: normal;
			font-weight: 400 !important;
			line-height: 1.2 !important;
			font-size: 26px;
			margin-top: 30px;
			margin-bottom: 24px;
			padding-bottom: 24px;
			border-bottom: 1px solid $text_colour;

			@mixin screen-size-900 {
				font-size: 28px;
				margin-bottom: 32px;
			}

			@media screen and (min-width: 1200px) {
				margin-bottom: 44px;
			}
		}

		&-details {
			max-width: calc(100% - 110px);

			p {
				color: $text_colour !important;
				font-family: $secondary_font;
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 200%;

				@mixin screen-size-900 {
					font-size: 28px;
				}
			}
		}
	}

	&__nav {
		&-button {
			background-color: $orange;
			width: 39px;
			height: 39px;
			border: 1px solid $orange;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (min-width: 700px) {
				width: 45px;
				height: 45px;
			}

			@media screen and (min-width: 1080px) {
				width: 49px;
				height: 49px;
			}

			svg {
				path {
					fill: $white;
				}
			}

			&:hover {
				background-color: $grey4;
				border-color: $grey4;
			}
		}
	}

	.owl-nav {
		position: relative;
		display: flex;
		justify-content: flex-start;
		left: 30px;
		gap: 20px;

		@media screen and (min-width: 768px) {
			position: absolute;
			bottom: 6vh;
			right: 30px;
			justify-content: flex-end;
		}

		@media screen and (min-width: 1280px) {
			right: calc((100vw - 1220px) / 2);
 		}

		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
	}
}
