.customisable-hero {
	overflow: hidden;
	background-color: transparent;

	.owl-stage {
		display: flex;
	}

	&__slider {
		position: relative;
	}

	&__slide {
		height: calc(100vh - 75px);
		width: 100vw;
		position: relative;

		@media screen and (min-width: 950px) {
			height: calc(100vh - 90px);
		}
	}

	&__background {
		z-index: 1;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		&-image,
		&-video {
			position: relative;
			height: 100%;
			width: 100%;

			img,
			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__overlay {
		position: relative;
		z-index: 2;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%);

		.col-12 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		h1 {
			color: #fff;
			font-family: $primary_font;
			font-style: normal;
			font-weight: 400;
			text-transform: uppercase;
			font-size: 32px;
			line-height: 125%;
			letter-spacing: 1.28px;

			@media screen and (min-width: 700px) {
				font-size: 40px;
				letter-spacing: 1.8px;
			}

			@media screen and (min-width: 950px) {
				font-size: 50px;
				line-height: 120%;
				letter-spacing: 2.2px;
			}

			@media screen and (min-width: 1080px) {
				font-size: 65px;
				line-height: 115%;
				letter-spacing: 2.6px;
			}
		}

		p {
			color: #fff;
			font-family: $secondary_font;
			text-align: center;
			font-style: normal;
			max-width: 880px;
			font-weight: 400;
			font-size: 16px;
			line-height: 175%;
			margin-top: 12px;

			@media screen and (min-width: 700px) {
				margin-top: 18px;
			}

			@media screen and (min-width: 950px) {
				font-size: 18px;
				line-height: 170%;
				margin-top: 24px;
			}

			@media screen and (min-width: 1080px) {
				font-size: 20px;
				line-height: 160%;
				margin-top: 30px;
			}
		}
	}

	&__nav {
		&-button {
			background-color: transparent;
			width: 39px;
			height: 39px;
			border: 1px solid white;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (min-width: 700px) {
				width: 45px;
				height: 45px;
			}

			@media screen and (min-width: 1080px) {
				width: 49px;
				height: 49px;
			}

			&:hover {
				background-color: rgba(black, 0.5);
			}
		}
	}

	&__ctas {
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		gap: 20px;

		@media screen and (min-width: 700px) {
			margin-top: 45px;
			gap: 30px;
		}

		@media screen and (min-width: 950px) {
			flex-direction: row;
			margin-top: 60px;
		}

		@media screen and (min-width: 1080px) {
			margin-top: 80px;
		}

		.c__button {
			color: $white;
			border-color: $white;

			&:hover {
				color: $orange;
			}
		}
	}

	.owl-item {
		.customisable-hero__overlay {
			.col-12 {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				text-align: left;
				align-items: flex-start;
				padding-left: 15px;
				padding-right: 15px;

				h1 {
					text-align: left;
				}

				p {
					text-align: left;
				}
			}
		}

		.customisable-hero__ctas {
			gap: 20px;
		}
	}

	.owl-nav {
		position: absolute;
		bottom: 5vh;
		right: 5vw;

		button {
			background-color: transparent;
			border: none;
		}
	}
}
