$primary_font: 'Epilepsja-Outline';
$secondary_font: 'theano_didotregular';
$alt_font: 'canada-type-gibson';

$grey1: #f2f0ed;
$grey2: #D9D9D9;
$grey3: #5C707C;
$grey4: #5C707C;
$white: white;
$aqua: #EBF1EF;
$orange: #EE8030;

$btn_bg_colour: $grey2;
$btn_text_colour: $orange;

$text_colour: $grey4;

$grid_width: 1280px;
