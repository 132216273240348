.customisable-tct {
	margin: 50px 0 40px;

	@media screen and (min-width: 1080px) {
		margin: 70px 0 50px;
	}

	@media screen and (min-width: 1200px) {
		margin: 85px 0 60px;
	}

	&__image {
		margin-top: 20px;

		@media screen and (min-width: 900px) {
			margin-top: 28px;
		}

		@media screen and (min-width: 1200px) {
			margin-top: 35px;
		}
 	}

	&__copy {
		color: $text_colour;
		font-family: $secondary_font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 200%;
		margin-top: 20px;

		@mixin screen-size-900 {
			font-size: 17px;
			margin-top: 28px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 18px;
			margin-top: 35px;
		}
	}

	&__ctas {
		display: flex;
		flex-wrap: wrap;
		margin-top: 36px;
		gap: 30px;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__content {
		width: 100%;
		margin-bottom: 40px;

		@media screen and (min-width: 700px) {
			width: 46%;
		}
	}
}
