.dand-custom-modules {
	line-height: 1.2;
	background-color: $grey1;
	padding-bottom: 1px;

	.customisable-container {
		width: 100%;
		max-width: $grid_width;
		margin: 0 auto;
	
		padding-left: 30px;
		padding-right: 30px;
	
		.customisable-row {
			display: flex;
			flex-wrap: wrap;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.container {
		width: 100%;
		max-width: $grid_width;
		margin: 0 auto;
		padding-left: 30px;
		padding-right: 30px;

		.row {
			display: flex;
			flex-wrap: wrap;
			margin-left: 0;
			margin-right: 0;
		}

		.col-sm-6 {
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;

			@media only screen and (min-width: 576px) {
				width: 50%;
			}
		}
		.col-md-4 {
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;

			@media only screen and (min-width: 768px) {
				width: 33.33%;
			}
		}
		.col-md-6 {
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;

			@media only screen and (min-width: 768px) {
				width: 50%;
			}
		}

		.col-12 {
			width: 100%;
		}
	}
}

.c__button {
	display: inline-block;
	font-family: $alt_font;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	//min-width: 160px;
	text-align: center;
	line-height: 1.1;
	padding: 15px 0 0 0;
	transition: all ease 300ms;
	background-color: transparent;
	color: $btn_text_colour;
	text-decoration: none;
	border-top: 3px solid $btn_text_colour;

	&:hover {
		color: $text_colour;
	}

	// &--alt {
	// 	background-color: $btn_text_colour;
	// 	color: $btn_bg_colour;

	// 	&:hover {
	// 		background-color: white;
	// 		color: $btn_bg_colour;
	// 	}
	// }
}

.customisable-absolute-cover-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.customisable-h2 {
	font-family: $primary_font !important;
	text-transform: uppercase !important;
	color: $text_colour !important;
	font-weight: 400 !important;
	letter-spacing: 0.08em !important;
	font-size: 26px !important;
	line-height: 1 !important;

	@media only screen and (min-width: 992px) {
		font-size: 38px !important;
	}

	&--white {
		color: $white !important;
	}
}

.customisable-h3 {
	font-family: $primary_font !important;
	text-transform: uppercase !important;
	color: $text_colour !important;
	font-weight: 700 !important;
	letter-spacing: 0.08em !important;
	font-size: 21px !important;
	line-height: 1 !important;

	@media only screen and (min-width: 992px) {
		font-size: 26px !important;
	}

	&--white {
		color: $white !important;
	}
}

.page-template-page-customizable-landing {
	background: $white;
	background-image: none !important;

	.booking-footer {
		display: none;
	}
}
