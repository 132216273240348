.customisable-free-editor {
	padding: 50px 0;

	@mixin screen-size-900 {
		padding: 70px 0;
	}

	@media screen and (min-width: 1080px) {
		padding: 90px 0;
	}

	@media screen and (min-width: 1200px) {
		padding: 105px 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #402431;
		font-family: $primary_font;
	}

	p,
	ul,
	li,
	ol,
	a {
		color: #898285;
		font-family: $secondary_font;
	}

	a {
		color: #ee8030;

		&:hover {
			color: #ee8030;
			text-decoration: underline;
		}
	}

	h1 {
		font-weight: 300;
		line-height: normal;
		font-size: 26px;
		margin-bottom: 10px;

		@mixin screen-size-900 {
			font-size: 27px;
			margin-bottom: 15px;
		}

		@media screen and (min-width: 1080px) {
			font-size: 32px;
			margin-bottom: 20px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 36px;
			margin-bottom: 25px;
		}
	}

	h2 {
		font-size: 24px;
		margin-bottom: 10px;

		@mixin screen-size-900 {
			font-size: 26px;
			margin-bottom: 13px;
		}

		@media screen and (min-width: 1080px) {
			font-size: 28px;
			margin-bottom: 16px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 30px;
			margin-bottom: 20px;
		}
	}

	h3 {
		font-size: 20px;
		margin-bottom: 10px;

		@mixin screen-size-900 {
			font-size: 22px;
			margin-bottom: 13px;
		}

		@media screen and (min-width: 1080px) {
			font-size: 24px;
			margin-bottom: 16px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 26px;
			margin-bottom: 20px;
		}
	}

	h3 {
		font-size: 19px;
		margin-bottom: 10px;

		@mixin screen-size-900 {
			font-size: 20px;
			margin-bottom: 13px;
		}

		@media screen and (min-width: 1080px) {
			font-size: 22px;
			margin-bottom: 16px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 24px;
			margin-bottom: 20px;
		}
	}

	h4 {
		font-size: 18px;
		margin-bottom: 10px;

		@mixin screen-size-900 {
			font-size: 19px;
			margin-bottom: 13px;
		}

		@media screen and (min-width: 1080px) {
			font-size: 20px;
			margin-bottom: 16px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 22px;
			margin-bottom: 20px;
		}
	}

	h5,
	h6 {
		font-size: 17px;
		margin-bottom: 10px;
		margin-top: 0;
		font-weight: normal;

		@mixin screen-size-900 {
			font-size: 18px;
			margin-bottom: 13px;
		}

		@media screen and (min-width: 1080px) {
			font-size: 19px;
			margin-bottom: 16px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}

	p,
	ul,
	li,
	ol,
	a {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 8px;

		@mixin screen-size-900 {
			font-size: 17px;
			margin-bottom: 10px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 18px;
			margin-bottom: 12px;
		}
	}

	ul,
	ol {
		margin-left: 20px;
		margin-bottom: 20px;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}
}
