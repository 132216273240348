.customisable-event-boxes {
	margin: 50px 0 25px;

	@media only screen and (min-width: 992px) {
		margin: 80px 0 55px;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px !important;
	}

	&__content {
		padding: 50px 0;

		p {
			max-width: 800px;
			margin: 0 auto;
			text-align: center;
		}
	}

	&__campaign-tile {
		margin-bottom: 40px;

		&__img {
			background-position: center;
			background-size: cover;
			height: 320px;
			position: relative;
			width: 100%;
		}

		h4 {
			bottom: 0;
			padding: 25px 0 25px 25px;
			position: absolute;
			z-index: 20;
			width: 80%;
		}

		p {
			font-family: $secondary_font;
			font-size: 16px;
		}

		&__overlay {
			background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 0;
		}

		&__content {
			padding: 25px 0 0;
			position: relative;
			z-index: 20;

			p {
				margin-bottom: 25px;
			}
		}
	}

	&__filter {
		display: flex;
		margin: 30px auto;
		width: calc(100% - 30px);
		padding-left: 15px;
		padding-right: 15px;

		@media only screen and (min-width: 576px) {
			width: 95%;
		}

		@media only screen and (min-width: 768px) {
			width: 80%;
			margin: 20px auto 50px auto;
		}

		@media only screen and (min-width: 1200px) {
			width: 75%;
		}
		span {
			margin-right: -1px;
			text-transform: uppercase;
			font-family: $secondary_font;
			padding: 39px 25px 42px;
			color: $text_colour;
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: 1px;
			line-height: 19px;

			@media only screen and (max-width: 600px) {
				display: none;
			}
		}

		a {
			flex-grow: 1;
			outline: 0;
			display: inline-block;
			text-align: center;
			text-decoration: none;
			color: $text_colour;
			vertical-align: middle;
			position: relative;
			margin-right: -1px;
			line-height: 32px;
			padding: 15px 0;
			font-size: 1em;

			@media only screen and (min-width: 601px) {
				padding: 25px 0;
				font-size: 20px;
			}

			@media only screen and (min-width: 1151px) {
				padding: 30px 0;
			}

			&.current {
				color: #fff;
				background-color: $text_colour;
			}
		}
	}
}
