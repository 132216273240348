/*! Made with Bones: http://themble.com/bones :) */
/******************************************************************
Site Name:
Author:

Stylesheet: Main Stylesheet

Here's where the magic happens. Here, you'll see we are calling in
the separate media queries. The base mobile goes outside any query
and is called at the beginning, after that we call the rest
of the styles inside media queries.

Helpful articles on Sass file organization:
http://thesassway.com/advanced/modular-css-naming-conventions

******************************************************************/
/***
	Customisable landing page
***/
.dand-custom-modules {
  line-height: 1.2;
  background-color: #f2f0ed;
  padding-bottom: 1px;
}
.dand-custom-modules .customisable-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
.dand-custom-modules .customisable-container .customisable-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
}
.dand-custom-modules .container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
.dand-custom-modules .container .row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
}
.dand-custom-modules .container .col-sm-6 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media only screen and (min-width: 576px) {
  .dand-custom-modules .container .col-sm-6 {
    width: 50%;
  }
}
.dand-custom-modules .container .col-md-4 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .dand-custom-modules .container .col-md-4 {
    width: 33.33%;
  }
}
.dand-custom-modules .container .col-md-6 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .dand-custom-modules .container .col-md-6 {
    width: 50%;
  }
}
.dand-custom-modules .container .col-12 {
  width: 100%;
}

.c__button {
  display: inline-block;
  font-family: "canada-type-gibson";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 1.1;
  padding: 15px 0 0 0;
  transition: all ease 300ms;
  background-color: transparent;
  color: #EE8030;
  text-decoration: none;
  border-top: 3px solid #EE8030;
}
.c__button:hover {
  color: #5C707C;
}

.customisable-absolute-cover-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.customisable-h2 {
  font-family: "Epilepsja-Outline" !important;
  text-transform: uppercase !important;
  color: #5C707C !important;
  font-weight: 400 !important;
  letter-spacing: 0.08em !important;
  font-size: 26px !important;
  line-height: 1 !important;
}
@media only screen and (min-width: 992px) {
  .customisable-h2 {
    font-size: 38px !important;
  }
}
.customisable-h2--white {
  color: white !important;
}

.customisable-h3 {
  font-family: "Epilepsja-Outline" !important;
  text-transform: uppercase !important;
  color: #5C707C !important;
  font-weight: 700 !important;
  letter-spacing: 0.08em !important;
  font-size: 21px !important;
  line-height: 1 !important;
}
@media only screen and (min-width: 992px) {
  .customisable-h3 {
    font-size: 26px !important;
  }
}
.customisable-h3--white {
  color: white !important;
}

.page-template-page-customizable-landing {
  background: white;
  background-image: none !important;
}
.page-template-page-customizable-landing .booking-footer {
  display: none;
}

.customisable-banner__container--standard-width {
  max-width: 1280px;
  padding: 0 30px;
  margin: 50px auto;
}
@media screen and (min-width: 1080px) {
  .customisable-banner__container--standard-width {
    margin: 90px auto;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-banner__container--standard-width {
    margin: 105px auto;
  }
}
.customisable-banner__image {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
  overflow: hidden;
}
.customisable-banner__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}
.customisable-banner__image-caption {
  color: #5C707C;
  font-family: "theano_didotregular";
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  font-size: 18px;
  margin-top: 20px;
}
.customisable-banner__image-caption p {
  transition: transform 0.5s ease-in-out;
  font-family: "theano_didotregular";
}
.customisable-banner__container--full-width .customisable-banner__image-caption {
  padding-left: 30px;
}
.customisable-banner__link {
  text-decoration: none;
}
.customisable-banner__link:hover .customisable-banner__image img {
  transform: scale(1.1);
}
.customisable-banner__link:hover .customisable-banner__image-caption p {
  color: #5C707C !important;
}

.customisable-cwf {
  margin-bottom: 30px;
}
.customisable-cwf .row {
  margin: 0 -15px !important;
}
@media only screen and (min-width: 992px) {
  .customisable-cwf {
    margin-bottom: 50px;
  }
}
.customisable-cwf__header {
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (min-width: 992px) {
  .customisable-cwf__header {
    margin-bottom: 65px;
  }
}
.customisable-cwf__horizontal-line {
  background-color: #5C707C;
  height: 1px;
  opacity: 0.25;
  width: 100%;
}
.customisable-cwf__horizontal-line.mb {
  margin-bottom: 40px;
}
.customisable-cwf__filters {
  background-color: #5C707C;
  color: white;
  font-family: "theano_didotregular";
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filters {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 992px) {
  .customisable-cwf__filters {
    margin-bottom: 50px;
  }
}
.customisable-cwf__filters--filter-by {
  display: none;
}
@media only screen and (min-width: 900px) {
  .customisable-cwf__filters--filter-by {
    display: block;
    font-size: 16px;
    color: white;
    font-family: "theano_didotregular";
  }
}
.customisable-cwf__filters--holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filters--holder {
    flex-wrap: nowrap;
  }
}
.customisable-cwf__filter {
  cursor: pointer;
  display: inline-block;
  min-width: 50%;
  padding: 18px 15px;
  position: relative;
  font-family: "Epilepsja-Outline";
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filter {
    min-width: 110px;
  }
}
@media screen and (min-width: 700px) {
  .customisable-cwf__filter {
    min-width: 170px;
    padding: 18px 30px;
  }
}
@media screen and (min-width: 900px) {
  .customisable-cwf__filter {
    padding: 18px 40px;
  }
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filter:nth-of-type(1) {
    border-bottom: 0;
    border-left: 0;
  }
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filter:nth-of-type(2) {
    border-bottom: 0;
  }
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filter:nth-of-type(3) {
    border-left: 0;
  }
}
.customisable-cwf__filter[data-selected]:not([data-selected=""]) {
  background-color: #EE8030;
}
.customisable-cwf__filter p,
.customisable-cwf__filter p span {
  font-family: "theano_didotregular";
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
  color: white;
}
@media screen and (min-width: 700px) {
  .customisable-cwf__filter p,
  .customisable-cwf__filter p span {
    font-size: 18px;
  }
}
.customisable-cwf__filter#all {
  min-width: 50%;
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filter#all {
    min-width: 65px;
  }
}
@media screen and (min-width: 700px) {
  .customisable-cwf__filter#all {
    min-width: 110px;
  }
}
.customisable-cwf__filter#all.selected {
  background-color: #EE8030;
}
.customisable-cwf__filter ul {
  background-color: #5C707C;
  display: none;
  left: -1px;
  max-height: 300px;
  overflow: scroll;
  padding: 20px 15px 10px;
  position: absolute;
  top: 57px;
  width: calc(100% + 2px);
  z-index: 20;
  margin: 0;
}
@media screen and (min-width: 700px) {
  .customisable-cwf__filter ul {
    padding: 20px 20px 10px;
  }
}
@media screen and (min-width: 900px) {
  .customisable-cwf__filter ul {
    padding: 20px 25px 10px;
  }
}
.customisable-cwf__filter ul.active {
  display: block;
}
.customisable-cwf__filter ul li {
  cursor: pointer;
  font-family: "Epilepsja-Outline";
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  transition: 0.2s;
  list-style: none;
}
@media screen and (min-width: 500px) {
  .customisable-cwf__filter ul li {
    font-size: 16px;
  }
}
@media screen and (min-width: 700px) {
  .customisable-cwf__filter ul li {
    font-size: 18px;
  }
}
.customisable-cwf__filter ul li:hover {
  opacity: 0.5;
}
.customisable-cwf__tile {
  height: 230px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
@media screen and (min-width: 900px) {
  .customisable-cwf__tile {
    height: 280px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-cwf__tile {
    height: 330px;
  }
}
.customisable-cwf__tile-image {
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 120%;
  width: 120%;
  top: -10%;
  left: -10%;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
.customisable-cwf__tile:hover {
  cursor: pointer;
}
.customisable-cwf__tile:hover .customisable-cwf__tile--overlay {
  opacity: 1;
}
.customisable-cwf__tile:hover .customisable-cwf__tile-image {
  transform: scale(1.2);
  rotate: 20deg;
}
.customisable-cwf__tile:hover a {
  color: #EE8030;
}
.customisable-cwf__tile--content-holder {
  bottom: 20px;
  left: 20px;
  padding-right: 20px;
  position: absolute;
  z-index: 10;
}
@media screen and (min-width: 900px) {
  .customisable-cwf__tile--content-holder {
    bottom: 30px;
    left: 30px;
    padding-right: 30px;
  }
}
.customisable-cwf__tile--content-holder h4 {
  color: white;
  margin-bottom: 5px;
}
.customisable-cwf__tile--content-holder a {
  color: white;
}
.customisable-cwf__tile--content-holder a:after {
  content: url(../../images/graphics/right-arrow.svg);
  position: absolute;
  transform: translate(10px, 2px);
  transition: 0.2s;
}
.customisable-cwf__tile--content-holder a:hover:after {
  transform: translate(15px, 2px);
}
.customisable-cwf__tile--overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  height: 60%;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  position: absolute;
  transition: 0.3s;
  width: 100%;
}
.customisable-cwf .no-results {
  font-family: "theano_didotregular";
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .customisable-cwf .no-results {
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .customisable-cwf .no-results {
    font-size: 19px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-cwf .no-results {
    font-size: 20px;
  }
}

.customisable-enquiry-form {
  margin: 50px 0;
}
@media screen and (min-width: 1080px) {
  .customisable-enquiry-form {
    margin: 90px 0;
  }
}
.customisable-enquiry-form .customisable-h2 {
  text-align: center;
}
.customisable-enquiry-form__form {
  max-width: 900px;
  margin: 30px auto 0;
}
@media screen and (min-width: 1080px) {
  .customisable-enquiry-form__form {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-enquiry-form__form {
    margin-top: 50px;
  }
}
.customisable-enquiry-form__form form {
  padding-top: 20px;
}
.customisable-enquiry-form__form form.feedback-form {
  display: block;
  width: 100%;
}
.customisable-enquiry-form__form form .row {
  margin: 0 -20px;
}
.customisable-enquiry-form__form form .row .col-sm-6,
.customisable-enquiry-form__form form .row .col-xs-12 {
  padding: 10px;
}
.customisable-enquiry-form__form form label {
  color: #5C707C;
  display: block;
  font-family: "theano_didotregular";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.customisable-enquiry-form__form form input[type=text],
.customisable-enquiry-form__form form input[type=email],
.customisable-enquiry-form__form form select {
  border: 1px solid #5C707C;
  background: white !important;
  border-radius: 0px;
  font-family: "theano_didotregular";
  font-size: 16px;
  height: 55px;
  margin-bottom: 20px;
  padding-left: 20px;
  width: 100%;
}
.customisable-enquiry-form__form form input[type=text]::placeholder,
.customisable-enquiry-form__form form input[type=email]::placeholder,
.customisable-enquiry-form__form form select::placeholder {
  color: #5C707C;
}
.customisable-enquiry-form__form form textarea {
  border: 1px solid #5C707C;
  background: white;
  font-family: "theano_didotregular";
  margin-bottom: 20px;
  font-size: 16px;
  min-height: 160px;
  padding: 15px 0 0 20px;
  width: 100%;
}
.customisable-enquiry-form__form form .c__button {
  float: right;
}
.customisable-enquiry-form__form form .checkbox-holder input[type=checkbox] {
  border: 1px solid #5C707C;
  height: 25px;
  position: absolute;
  width: 25px;
  -webkit-appearance: none;
}
.customisable-enquiry-form__form form .checkbox-holder input[type=checkbox]:checked {
  background-image: url(../../images/graphics/tick.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}
.customisable-enquiry-form__form form .checkbox-holder label {
  color: #5C707C;
  font-size: 14px;
  line-height: 20px;
  padding-left: 40px;
}
.customisable-enquiry-form__form .w-form-done,
.customisable-enquiry-form__form .w-form-fail {
  display: none;
}
.customisable-enquiry-form__form .dd-enquiry-form__input, .customisable-enquiry-form__form .dd-enquiry-form__datepicker, .customisable-enquiry-form__form .dd-enquiry-form__textarea, .customisable-enquiry-form__form .dd-enquiry-form__select {
  color: #5C707C;
}
.customisable-enquiry-form__form .dd-enquiry-form__submit {
  background-color: transparent !important;
  width: auto !important;
  margin: 0 auto !important;
  display: block !important;
  color: #EE8030 !important;
  padding: 15px 0 0 0 !important;
  font-size: 14px !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top: 3px solid #EE8030;
}
.customisable-enquiry-form__form .dd-enquiry-form__submit:hover {
  border-color: #EE8030 !important;
  color: #5C707C !important;
}

.customisable-event-boxes {
  margin: 50px 0 25px;
}
@media only screen and (min-width: 992px) {
  .customisable-event-boxes {
    margin: 80px 0 55px;
  }
}
.customisable-event-boxes .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px !important;
}
.customisable-event-boxes__content {
  padding: 50px 0;
}
.customisable-event-boxes__content p {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.customisable-event-boxes__campaign-tile {
  margin-bottom: 40px;
}
.customisable-event-boxes__campaign-tile__img {
  background-position: center;
  background-size: cover;
  height: 320px;
  position: relative;
  width: 100%;
}
.customisable-event-boxes__campaign-tile h4 {
  bottom: 0;
  padding: 25px 0 25px 25px;
  position: absolute;
  z-index: 20;
  width: 80%;
}
.customisable-event-boxes__campaign-tile p {
  font-family: "theano_didotregular";
  font-size: 16px;
}
.customisable-event-boxes__campaign-tile__overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.customisable-event-boxes__campaign-tile__content {
  padding: 25px 0 0;
  position: relative;
  z-index: 20;
}
.customisable-event-boxes__campaign-tile__content p {
  margin-bottom: 25px;
}
.customisable-event-boxes__filter {
  display: flex;
  margin: 30px auto;
  width: calc(100% - 30px);
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (min-width: 576px) {
  .customisable-event-boxes__filter {
    width: 95%;
  }
}
@media only screen and (min-width: 768px) {
  .customisable-event-boxes__filter {
    width: 80%;
    margin: 20px auto 50px auto;
  }
}
@media only screen and (min-width: 1200px) {
  .customisable-event-boxes__filter {
    width: 75%;
  }
}
.customisable-event-boxes__filter span {
  margin-right: -1px;
  text-transform: uppercase;
  font-family: "theano_didotregular";
  padding: 39px 25px 42px;
  color: #5C707C;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
}
@media only screen and (max-width: 600px) {
  .customisable-event-boxes__filter span {
    display: none;
  }
}
.customisable-event-boxes__filter a {
  flex-grow: 1;
  outline: 0;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #5C707C;
  vertical-align: middle;
  position: relative;
  margin-right: -1px;
  line-height: 32px;
  padding: 15px 0;
  font-size: 1em;
}
@media only screen and (min-width: 601px) {
  .customisable-event-boxes__filter a {
    padding: 25px 0;
    font-size: 20px;
  }
}
@media only screen and (min-width: 1151px) {
  .customisable-event-boxes__filter a {
    padding: 30px 0;
  }
}
.customisable-event-boxes__filter a.current {
  color: #fff;
  background-color: #5C707C;
}

.customisable-free-editor {
  padding: 50px 0;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor {
    padding: 90px 0;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor {
    padding: 105px 0;
  }
}
.customisable-free-editor h1,
.customisable-free-editor h2,
.customisable-free-editor h3,
.customisable-free-editor h4,
.customisable-free-editor h5,
.customisable-free-editor h6 {
  color: #402431;
  font-family: "Epilepsja-Outline";
}
.customisable-free-editor p,
.customisable-free-editor ul,
.customisable-free-editor li,
.customisable-free-editor ol,
.customisable-free-editor a {
  color: #898285;
  font-family: "theano_didotregular";
}
.customisable-free-editor a {
  color: #ee8030;
}
.customisable-free-editor a:hover {
  color: #ee8030;
  text-decoration: underline;
}
.customisable-free-editor h1 {
  font-weight: 300;
  line-height: normal;
  font-size: 26px;
  margin-bottom: 10px;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor h1 {
    font-size: 36px;
    margin-bottom: 25px;
  }
}
.customisable-free-editor h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor h2 {
    font-size: 28px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.customisable-free-editor h3 {
  font-size: 20px;
  margin-bottom: 10px;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor h3 {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
.customisable-free-editor h3 {
  font-size: 19px;
  margin-bottom: 10px;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor h3 {
    font-size: 22px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.customisable-free-editor h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor h4 {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor h4 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
.customisable-free-editor h5,
.customisable-free-editor h6 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: normal;
}
@media screen and (min-width: 1080px) {
  .customisable-free-editor h5,
  .customisable-free-editor h6 {
    font-size: 19px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor h5,
  .customisable-free-editor h6 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.customisable-free-editor p,
.customisable-free-editor ul,
.customisable-free-editor li,
.customisable-free-editor ol,
.customisable-free-editor a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
}
@media screen and (min-width: 1200px) {
  .customisable-free-editor p,
  .customisable-free-editor ul,
  .customisable-free-editor li,
  .customisable-free-editor ol,
  .customisable-free-editor a {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
.customisable-free-editor ul,
.customisable-free-editor ol {
  margin-left: 20px;
  margin-bottom: 20px;
}
.customisable-free-editor ul {
  list-style-type: disc;
}
.customisable-free-editor ol {
  list-style-type: decimal;
}

.customisable-hbp {
  padding: 50px 0 50px;
}
@media screen and (min-width: 1080px) {
  .customisable-hbp {
    padding: 70px 0 60px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-hbp {
    padding: 85px 0 70px;
  }
}
.customisable-hbp--grey {
  background-color: #f5f3f2;
}
.customisable-hbp__bullet-points {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.customisable-hbp__bullet-point {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (min-width: 500px) {
  .customisable-hbp__bullet-point {
    width: 50%;
    margin-bottom: 27px;
  }
}
@media screen and (min-width: 900px) {
  .customisable-hbp__bullet-point {
    width: 33.333%;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-hbp__bullet-point {
    margin-bottom: 35px;
  }
}
.customisable-hbp__bullet-point p {
  color: #5C707C;
  font-family: "theano_didotregular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center !important;
  max-width: 300px;
  margin: 0 auto;
  line-height: 1.5;
}
@media only screen and (min-width: 900px) {
  .customisable-hbp__bullet-point p {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1200px) {
  .customisable-hbp__bullet-point p {
    font-size: 26px;
  }
}

.customisable-hero {
  overflow: hidden;
  background-color: transparent;
}
.customisable-hero .owl-stage {
  display: flex;
}
.customisable-hero__slider {
  position: relative;
}
.customisable-hero__slide {
  height: calc(100vh - 75px);
  width: 100vw;
  position: relative;
}
@media screen and (min-width: 950px) {
  .customisable-hero__slide {
    height: calc(100vh - 90px);
  }
}
.customisable-hero__background {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.customisable-hero__background-image, .customisable-hero__background-video {
  position: relative;
  height: 100%;
  width: 100%;
}
.customisable-hero__background-image img,
.customisable-hero__background-image video, .customisable-hero__background-video img,
.customisable-hero__background-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customisable-hero__overlay {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%);
}
.customisable-hero__overlay .col-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.customisable-hero__overlay h1 {
  color: #fff;
  font-family: "Epilepsja-Outline";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: 1.28px;
}
@media screen and (min-width: 700px) {
  .customisable-hero__overlay h1 {
    font-size: 40px;
    letter-spacing: 1.8px;
  }
}
@media screen and (min-width: 950px) {
  .customisable-hero__overlay h1 {
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 2.2px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-hero__overlay h1 {
    font-size: 65px;
    line-height: 115%;
    letter-spacing: 2.6px;
  }
}
.customisable-hero__overlay p {
  color: #fff;
  font-family: "theano_didotregular";
  text-align: center;
  font-style: normal;
  max-width: 880px;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  margin-top: 12px;
}
@media screen and (min-width: 700px) {
  .customisable-hero__overlay p {
    margin-top: 18px;
  }
}
@media screen and (min-width: 950px) {
  .customisable-hero__overlay p {
    font-size: 18px;
    line-height: 170%;
    margin-top: 24px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-hero__overlay p {
    font-size: 20px;
    line-height: 160%;
    margin-top: 30px;
  }
}
.customisable-hero__nav-button {
  background-color: transparent;
  width: 39px;
  height: 39px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 700px) {
  .customisable-hero__nav-button {
    width: 45px;
    height: 45px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-hero__nav-button {
    width: 49px;
    height: 49px;
  }
}
.customisable-hero__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.customisable-hero__ctas {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 20px;
}
@media screen and (min-width: 700px) {
  .customisable-hero__ctas {
    margin-top: 45px;
    gap: 30px;
  }
}
@media screen and (min-width: 950px) {
  .customisable-hero__ctas {
    flex-direction: row;
    margin-top: 60px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-hero__ctas {
    margin-top: 80px;
  }
}
.customisable-hero__ctas .c__button {
  color: white;
  border-color: white;
}
.customisable-hero__ctas .c__button:hover {
  color: #EE8030;
}
.customisable-hero .owl-item .customisable-hero__overlay .col-12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
}
.customisable-hero .owl-item .customisable-hero__overlay .col-12 h1 {
  text-align: left;
}
.customisable-hero .owl-item .customisable-hero__overlay .col-12 p {
  text-align: left;
}
.customisable-hero .owl-item .customisable-hero__ctas {
  gap: 20px;
}
.customisable-hero .owl-nav {
  position: absolute;
  bottom: 5vh;
  right: 5vw;
}
.customisable-hero .owl-nav button {
  background-color: transparent;
  border: none;
}

.customisable-promo-boxes {
  margin: 50px 0;
}
@media screen and (min-width: 900px) {
  .customisable-promo-boxes {
    margin: 70px 0;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-promo-boxes {
    margin: 90px 0;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-promo-boxes {
    margin: 105px 0;
  }
}
.customisable-promo-boxes .row {
  margin: 0 -15px !important;
}
.customisable-promo-boxes .row .col-md-4 {
  padding: 10px;
}
@media screen and (min-width: 1080px) {
  .customisable-promo-boxes .row .col-md-4 {
    padding: 15px;
  }
}
.customisable-promo-boxes__heading-container {
  margin-bottom: 30px;
}
.customisable-promo-boxes__heading-container h2 {
  display: inline-block;
}
.customisable-promo-boxes__heading-container p {
  display: inline-block;
  font-size: 14px;
}
.customisable-promo-boxes .col-md-4 {
  height: calc(100vw - 32px);
}
@media screen and (min-width: 700px) {
  .customisable-promo-boxes .col-md-4 {
    height: calc((100vw - 32px) / 3);
  }
}
@media screen and (min-width: 1400px) {
  .customisable-promo-boxes .col-md-4 {
    max-height: 406px;
  }
}
.customisable-promo-boxes .col-md-4 .customisable-promo-boxes__box {
  height: 100%;
}
.customisable-promo-boxes .col-md-6 {
  padding: 10px 15px;
  height: calc(100vw - 32px);
}
@media screen and (min-width: 700px) {
  .customisable-promo-boxes .col-md-6 {
    height: calc((100vw - 32px) / 2);
  }
}
@media screen and (min-width: 1400px) {
  .customisable-promo-boxes .col-md-6 {
    max-height: 635px;
  }
}
.customisable-promo-boxes .col-md-6 .customisable-promo-boxes__box {
  height: 100%;
}
.customisable-promo-boxes__box {
  background-position: center;
  background-size: cover;
  cursor: pointer;
  height: 200px;
  overflow: hidden;
  position: relative;
}
.customisable-promo-boxes__box-image {
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 120%;
  width: 120%;
  top: -10%;
  left: -10%;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
@media screen and (min-width: 900px) {
  .customisable-promo-boxes__box {
    height: 290px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-promo-boxes__box {
    height: 330px;
  }
}
.customisable-promo-boxes__box:hover .customisable-promo-boxes__box--overlay {
  opacity: 1;
}
.customisable-promo-boxes__box:hover .customisable-promo-boxes__box-image {
  transform: scale(1.2);
  rotate: 20deg;
}
.customisable-promo-boxes__box:hover a {
  color: #EE8030;
}
.customisable-promo-boxes__box--content-holder {
  bottom: 20px;
  left: 0;
  padding: 0 20px;
  position: absolute;
  z-index: 10;
}
@media screen and (min-width: 900px) {
  .customisable-promo-boxes__box--content-holder {
    bottom: 30px;
    padding: 0 30px;
  }
}
.customisable-promo-boxes__box--content-holder h4 {
  color: white;
  margin-bottom: 5px;
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: uppercase;
}
.customisable-promo-boxes__box--content-holder a {
  margin-top: 10px;
}
.customisable-promo-boxes__box--content-holder a:hover:after {
  transform: translate(15px, 2px);
}
.customisable-promo-boxes__box--content-holder .c__button {
  color: white;
  border-color: white;
}
.customisable-promo-boxes__box--content-holder .c__button:hover {
  color: #EE8030;
}
.customisable-promo-boxes__box--overlay {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
  bottom: 0;
  height: 60%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  transition: 0.3s;
  width: 100%;
}

.customisable-tct {
  margin: 50px 0 40px;
}
@media screen and (min-width: 1080px) {
  .customisable-tct {
    margin: 70px 0 50px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-tct {
    margin: 85px 0 60px;
  }
}
.customisable-tct__image {
  margin-top: 20px;
}
@media screen and (min-width: 900px) {
  .customisable-tct__image {
    margin-top: 28px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-tct__image {
    margin-top: 35px;
  }
}
.customisable-tct__copy {
  color: #5C707C;
  font-family: "theano_didotregular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  margin-top: 20px;
}
@media screen and (min-width: 1200px) {
  .customisable-tct__copy {
    font-size: 18px;
    margin-top: 35px;
  }
}
.customisable-tct__ctas {
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  gap: 30px;
}
.customisable-tct__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.customisable-tct__content {
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (min-width: 700px) {
  .customisable-tct__content {
    width: 46%;
  }
}

.customisable-tib {
  position: relative;
  background-color: #f2f0ed;
  padding: 50px 0;
}
@media only screen and (max-width: 699px) {
  .customisable-tib--has-media.customisable-tib--media-left {
    padding: 0 0 50px;
  }
  .customisable-tib--has-media.customisable-tib--media-left .customisable-tib__media {
    margin-bottom: 40px;
  }
  .customisable-tib--has-media.customisable-tib--media-right {
    padding: 50px 0 0;
  }
  .customisable-tib--has-media.customisable-tib--media-right .customisable-tib__content {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 700px) {
  .customisable-tib {
    padding: 50px 0 80px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-tib {
    padding: 70px 0 90px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-tib {
    padding: 85px 0 100px;
  }
}
.customisable-tib__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customisable-tib > .container {
  position: relative;
}
.customisable-tib--alt {
  background-color: #EBF1EF;
}
.customisable-tib__media {
  height: 400px;
  width: 100%;
}
@media only screen and (min-width: 700px) {
  .customisable-tib__media {
    width: auto;
  }
}
@media screen and (min-width: 900px) {
  .customisable-tib__media {
    height: 500px;
  }
}
.customisable-tib__copy {
  color: #5C707C;
  font-family: "theano_didotregular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  margin-top: 20px;
}
@media screen and (min-width: 900px) {
  .customisable-tib__copy {
    font-size: 17px;
    margin-top: 28px;
  }
}
@media screen and (min-width: 1200px) {
  .customisable-tib__copy {
    font-size: 18px;
    margin-top: 35px;
  }
}
.customisable-tib__ctas {
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  gap: 30px;
}
.customisable-tib__row {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
@media screen and (min-width: 700px) {
  .customisable-tib__row--1.customisable-tib__row--media-left > div, .customisable-tib__row--1.customisable-tib__row--media-right > div {
    flex-direction: column;
    width: 46%;
  }
}
.customisable-tib__row--1.customisable-tib__row--media-full {
  flex-direction: column-reverse;
}
.customisable-tib__row--media-left {
  flex-direction: column-reverse;
}
@media screen and (min-width: 700px) {
  .customisable-tib__row--media-left {
    flex-direction: row-reverse;
  }
}
.customisable-tib__image, .customisable-tib__video {
  position: relative;
  height: 100%;
  width: 100%;
}
.customisable-tib__image img,
.customisable-tib__image video, .customisable-tib__video img,
.customisable-tib__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customisable-tls {
  background-color: #EBF1EF;
  overflow: hidden;
  padding: 73px 0;
  position: relative;
}
.customisable-tls .container {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}
.customisable-tls .owl-stage {
  display: flex;
}
.customisable-tls__slide-quote {
  color: #5C707C;
  font-family: "theano_didotregular" !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  font-size: 26px;
  margin-top: 30px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #5C707C;
}
@media screen and (min-width: 1200px) {
  .customisable-tls__slide-quote {
    margin-bottom: 44px;
  }
}
.customisable-tls__slide-details {
  max-width: calc(100% - 110px);
}
.customisable-tls__slide-details p {
  color: #5C707C !important;
  font-family: "theano_didotregular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 200%;
}
.customisable-tls__nav-button {
  background-color: #EE8030;
  width: 39px;
  height: 39px;
  border: 1px solid #EE8030;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 700px) {
  .customisable-tls__nav-button {
    width: 45px;
    height: 45px;
  }
}
@media screen and (min-width: 1080px) {
  .customisable-tls__nav-button {
    width: 49px;
    height: 49px;
  }
}
.customisable-tls__nav-button svg path {
  fill: white;
}
.customisable-tls__nav-button:hover {
  background-color: #5C707C;
  border-color: #5C707C;
}
.customisable-tls .owl-nav {
  position: relative;
  display: flex;
  justify-content: flex-start;
  left: 30px;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .customisable-tls .owl-nav {
    position: absolute;
    bottom: 6vh;
    right: 30px;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1280px) {
  .customisable-tls .owl-nav {
    right: calc((100vw - 1220px) / 2);
  }
}
.customisable-tls .owl-nav button {
  background-color: transparent;
  border: none;
  padding: 0;
}