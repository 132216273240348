/*! Made with Bones: http://themble.com/bones :) */

/******************************************************************
Site Name:
Author:

Stylesheet: Main Stylesheet

Here's where the magic happens. Here, you'll see we are calling in
the separate media queries. The base mobile goes outside any query
and is called at the beginning, after that we call the rest
of the styles inside media queries.

Helpful articles on Sass file organization:
http://thesassway.com/advanced/modular-css-naming-conventions

******************************************************************/

/***
	Customisable landing page
***/
@import 'customisable-landing/control';
